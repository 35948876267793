<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Add Assessment Form</h4>
        </div>
      </div>
    </div>

    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row mb-3">
                <!-- {{ module.name }} -->
                <div class="col-3">
                  <label for="remark"> Module Name </label>
                </div>
                <div class="col-6">
                  <v-select
                    v-model="module.name"
                    label="name"
                    :options="moduleList"
                    :selectable="(options) => isSelectedModule(options)"
                    :clearable="false"
                    :class="{
                      'p-invalid': v$.module.name.$error,
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.module.name.$errors,
                      value: 'Module name',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-3">
                  <label for="remark"> Duration </label>
                </div>
                <div class="col-8">
                  <div class="row">
                    <div class="col-6">
                      <input
                        type="number"
                        class="form-control"
                        v-model="module.duration"
                      />
                    </div>
                    <div class="col-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Days"
                        disabled
                      />
                    </div>
                  </div>

                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.module.duration.$errors,
                      value: 'Duration',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div
                v-for="(question, index) in module.question_list"
                :key="index"
              >
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="remark"> Question </label>
                  </div>
                  <div class="col-md-6">
                    <v-select
                      v-model="question.type"
                      label="name"
                      :options="checkQuestionStatus"
                      :selectable="(options) => isSelectedQuestion(options)"
                      :clearable="false"
                    >
                      <template #selected-option="{ name }">
                        <div style="max-width: 600px">
                          {{ name }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-md-2">
                    <!-- v-if="index+1 == module.question_list.length " -->
                    <div class="d-flex">
                      <div
                        v-if="module.question_list.length > 1"
                        class="me-2 mt-1 del"
                        @click="deleteQuestion(index)"
                      >
                        <i class="fas fa-trash-alt mx-1"></i>
                      </div>

                      <div
                        class=""
                        v-if="hasPermissions(['ata-assessment-module-create'])"
                      >
                        <template
                          v-if="index === module.question_list.length - 1"
                        >
                          <button
                            v-show="!loading"
                            type="button"
                            @click="addQuestion()"
                            class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                          >
                            <i class="mdi mdi-plus-circle"></i> Add
                          </button>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- end row -->
              <div
                class="row"
                v-if="hasPermissions(['ata-assessment-module-create'])"
              >
                <!-- <div class="col-6"></div>       -->
                <div class="col-md-6 mx-auto">
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-10">
                      <div class="mt-3 mb-3 ms-3">
                        <router-link :to="{ name: 'assessment-module' }">
                          <button
                            type="button"
                            class="btn w-sm btn-secondary me-5"
                          >
                            Cancel
                          </button>
                        </router-link>
                        <button
                          :disabled="loading ? true : false"
                          type="button"
                          class="btn w-sm btn-success waves-effect waves-light px-3"
                          @click="assessmentModuleCreate()"
                        >
                          <span
                            v-if="loading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {{ loading == true ? "Loading..." : "Save" }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";
import { options } from "dropzone";
import { data } from "jquery";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      selected: {},
      moduleList: [],
      question_assessment: [],
      aseesment_modules: [],
      module: {
        name: "",
        duration: "",
        question_list: [{ type: "" }],
      },
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    async assessmentModuleCreate() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.toast.error("Please fill all input!");
        return;
      }
      const sendData = {
        module_id: this.module.name.id,
        duration: this.module.duration,
        assessment_question_id: this.module.question_list.map(
          (val) => val.type.id
        ),
      };

      const containsUndefined = sendData?.assessment_question_id.some(
        (item) => item === undefined
      );

      if (containsUndefined) return this.toast.error("Please fill all inputs!");

      this.loading = true;

      await axios
        .post(`${this.baseUrl}admin/v2/assessment-module`, sendData)
        .then(() => {
          this.$router.push({ name: "assessment-module" });
          this.toast.success("Successfully created Assessment Question.");
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.toast.error("Please fill all input!");
          } else {
            this.toast.error("Something Went Wrong!");
          }
          this.error = error.response;
          this.loading = false;
          this.$Progress.finish();
        });
    },
    isSelectedModule(options) {
      return (
        !this.aseesment_modules.some((data) => data.module_id == options.id) &&
        this.module.name.id !== options.id
      );
    },
    async addQuestion() {
      this.module.question_list.push({ type: "" });
    },
    async deleteQuestion(index) {
      let data = this.module.question_list.filter((val, i) => i !== index);
      this.module.question_list = data;
    },
    isSelectedQuestion(options) {
      return !this.module.question_list.some(
        (val) => val.type.name == options.name
      );
    },
    async getModule() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/modules`)
        .then((response) => {
          this.moduleList = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },
    async getQuestionAssessment() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/assessment-question`)
        .then((response) => {
          this.question_assessment = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },
    async getAssessmentModule() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/assessment-module`)
        .then((response) => {
          this.aseesment_modules = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },
  },
  computed: {
    checkQuestionStatus() {
      return this.question_assessment.filter((val) => val.status);
    },
  },
  validations() {
    return {
      module: {
        name: { required },
        duration: { required },
        question_list: [{ type: { required } }],
      },
    };
  },
  async created() {
    await this.getModule();
    await this.getQuestionAssessment();
    await this.getAssessmentModule();
  },
};
</script>

<style>
.del {
  color: rgb(126, 26, 26);
}
.del::hover {
  cursor: pointer;
}
/* .custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
} */
</style>
